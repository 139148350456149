<template>
  <div id="screanHeight" style="height: 100%;display: flex;flex-direction: column;">
    <div class="yt-breadcrumb-wrapper">
      <el-breadcrumb class="yt-breadcrumb">
        <el-breadcrumb-item>学习管理</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/manage/study/task' }">学习任务</el-breadcrumb-item>
        <el-breadcrumb-item>任务详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="content">
      <div class="title centerStyle" style="padding: 0 20px">
        <Button @click="addLTask" type="primary">
          <Tooltip content="新增学习任务">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-xinzeng" />
            </svg>
            新增学习任务
          </Tooltip>
        </Button>
        <Button :disabled="Isdisplay" type="primary" @click="IsDelete = true">
          <Tooltip content="删除">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-shanchu" />
            </svg>
            删除
          </Tooltip>
        </Button>
        <Button :disabled="Isdisplay" type="primary" @click="toSetTask = true">
          <Tooltip content="修改">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-bianji" />
            </svg>
            修改完成任务条件
          </Tooltip>
        </Button>
      </div>
      <!--        <vueScroll :ops="ops">-->
      <div class="table">
        <el-table class="yt-table" @selection-change="selectTask" :data="taskList" v-loading="tableLoading" @row-click="seeDetail">
          <el-table-column width="31" type="selection" align="center" />
          <el-table-column label="课程名称" prop="courseName" show-overflow-tooltip />
          <el-table-column label="完成条件(学习百分比)" prop="courseName" show-overflow-tooltip>
            <template slot-scope="{ row }">
              <span>{{ row.finishProgass }}%</span>
            </template>
          </el-table-column>
          <el-table-column label="及格线" prop="testPassPoint" show-overflow-tooltip />
          <el-table-column label="测试分数" prop="testPoint" show-overflow-tooltip />
          <el-table-column label="最大次数" prop="examMaxCount" show-overflow-tooltip />
          <el-table-column label="学习进度" prop="courseName" show-overflow-tooltip>
            <template slot-scope="{ row }">
              <div :class="row.learnProgass >= 60 ? 'finLearn' : 'noLearn'">{{ (row.learnProgass * 100).toFixed(2) }}%</div>
            </template>
          </el-table-column>
          <el-table-column label="学习状态" prop="courseName" show-overflow-tooltip>
            <template slot-scope="{ row }">
              <div>
                <Tag type="dot" :color="learnStatus[row.learnStatus].color">
                  {{ learnStatus[row.learnStatus].text }}
                </Tag>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="上次学习时间" prop="courseName" show-overflow-tooltip>
            <template slot-scope="{ row }">
              <div>{{ row.lastLearnTime }}</div>
            </template>
          </el-table-column>
          <el-table-column label="任务创建者" prop="creator" show-overflow-tooltip />
          <el-table-column label="设置日期" prop="setTime" show-overflow-tooltip />
          <el-table-column label="操作" width="80px">
            <template slot-scope="{ row }">
              <div class="iconStyle">
                <Tooltip content="编辑" :transfer="true">
                  <svg class="icon" aria-hidden="true" @click.stop="updateTaskFinsh(row)">
                    <use xlink:href="#icon-bianji" />
                  </svg>
                </Tooltip>
                <Tooltip content="删除" :transfer="true">
                  <svg class="icon" aria-hidden="true" @click.stop="deleteTask(row)">
                    <use xlink:href="#icon-shanchu" />
                  </svg>
                </Tooltip>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!--        </vueScroll>-->
      <div class="page">
        <Page
          class="yt-page"
          :class="'yt-page-' + (taskTotal.toString().length > 1 ? taskTotal.toString().length : 2)"
          show-sizer
          show-elevator
          show-total
          :page-size-opts="[10, 20, 40, 100]"
          :total="taskTotal"
          @on-page-size-change="changeSize"
          @on-change="handlePageNum"
          :current="pageNumber + 1"
        />
      </div>
    </div>
    <div v-if="toSetTask">
      <UpdateTask :toSet="toSetTask" :courseId="courseIds" @upTaskByUserId="updateTask" @on-cancel="close" :title="title" />
    </div>
    <div v-if="seeDetailStatus">
      <personTask :title="titleCN" :courseId="rowCrouseId" :userId="userId" :status="seeDetailStatus" @on-cancel="closeP" />
    </div>
    <div v-if="IsDelete">
      <DeleteModal :value="IsDelete" @on-cancel="closeDeleted" @on-ok="delSeleTask" />
    </div>
    <div v-if="addLearningTask">
      <learnTask :title="setTitle" :open="addLearningTask" @close="closeModal" @addLearnTask="submitTask" />
    </div>
  </div>
</template>

<script>
import learnTask from '../type/learnTask'
import UpdateTask from '../type/UpdateTask'
import personTask from '../type/personTask'
import DeleteModal from '../../../common/DeleteModal'
import learn from '../../../../api/learnTask'
import vueScroll from 'vuescroll'
export default {
  name: 'taskDetail',
  components: { UpdateTask, personTask, DeleteModal, vueScroll, learnTask },
  data() {
    return {
      taskList: [],
      taskTotal: 0,
      pageNumber: 0,
      pageSize: 10,
      toSetTask: false,
      title: '修改学习完成任务条件',
      seeDetailStatus: false,
      IsDelete: false,
      userId: '',
      operatorId: 0,
      courseByIds: [],
      courseIds: [],
      Isdisplay: true,
      addLearningTask: false,
      setTitle: '',
      titleCN: '',
      rowCrouseId: 0,
      row: null,
      learnStatus: [
        { color: 'default', text: '未学习' },
        { color: 'primary', text: '学习中' },
        { color: 'success', text: '已完成' }
      ],
      tableLoading: false
    }
  },
  created() {
    this.userId = this.$handleParams('taskDetailData').userId
  },
  mounted() {
    this.getAllTaskByUserId()
  },
  methods: {
    getTime(time) {
      //将时间戳转换成时间格式
      let date = new Date(time)
      let year = date.getFullYear()
      let month = date.getMonth() + 1
      let day = date.getDate()
      month = month < 10 ? '0' + month : month
      day = day < 10 ? '0' + day : day
      date = year + '-' + month + '-' + day
      return date
    },
    changeSize(val) {
      this.pageSize = val
      this.getAllTaskByUserId()
    },
    handlePageNum(page) {
      this.pageNumber = page - 1
      this.getAllTaskByUserId()
    },
    getAllTaskByUserId() {
      this.taskList = []
      this.tableLoading = true
      const form = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize
      }
      learn
        .getAllTaskByUserId(form, this.userId)
        .then(res => {
          this.taskTotal = res.res.total
          const list = res.res.data
          list.map(l => {
            this.taskList.push({
              id: l.id,
              courseId: l.courseId,
              courseName: l.courseName,
              finishProgass: l.passProgress,
              examMaxCount: l.examMaxCount,
              testPassPoint: l.passExamScore,
              testPoint: l.hasOwnProperty('currentExamScore') ? l.currentExamScore : '暂无分数',
              learnProgass: l.courseProgress,
              creator: l.creatorName,
              learnStatus: l.status,
              setTime: this.getTime(l.createTime),
              lastLearnTime: l.recentStudyTime ? this.getTime(l.recentStudyTime) : '暂未学习'
            })
          })
        })
        .finally(() => {
          this.tableLoading = false
        })
    },
    updateTaskFinsh(row) {
      this.courseIds = []
      this.courseIds.push(row.courseId)
      this.toSetTask = true
    },
    updateTask(data) {
      learn.updateTask(null, this.userId, data).then(res => {
        if (res.res) {
          this.$message.success('修改成功')
          this.getAllTaskByUserId()
          this.toSetTask = false
          this.Isdisplay = true
        }
      })
    },
    close(data) {
      this.toSetTask = data
    },
    closeP(data) {
      this.seeDetailStatus = data
    },
    selectTask(row) {
      this.courseIds = []
      this.courseByIds = row
      row.map(r => {
        this.courseIds.push(r.courseId)
      })
      this.courseIds.length > 0 ? (this.Isdisplay = false) : (this.Isdisplay = true)
    },
    seeDetail(row) {
      this.titleCN = row.courseName
      this.rowCrouseId = row.courseId
      this.seeDetailStatus = true
    },
    deleteTask(row) {
      this.courseByIds = []
      this.courseByIds.push(row)
      this.IsDelete = true
    },
    closeDeleted(data) {
      this.IsDelete = data
    },
    delSeleTask() {
      const deleteStudyTaskListDTO = {
        deleteStudyTaskDTOS: [],
        operatorId: this.operatorId
      }
      this.courseByIds.map(c => {
        deleteStudyTaskListDTO.deleteStudyTaskDTOS.push({
          courseIds: [c.courseId],
          userId: Number(this.userId)
        })
      })
      learn.batachDelTask(deleteStudyTaskListDTO).then(res => {
        if (res.res) {
          this.$message.success('删除成功')
          this.getAllTaskByUserId()
        }
      })
      this.IsDelete = false
    },
    addLTask() {
      //新增
      this.setTitle = '设置'
      this.addLearningTask = true
    },
    closeModal() {
      this.addLearningTask = false
    },
    submitTask(departmentAddStudyTaskDTO) {
      departmentAddStudyTaskDTO.userIds = []
      departmentAddStudyTaskDTO.userIds.push(this.userId)
      learn.addLearnTask(departmentAddStudyTaskDTO).then(res => {
        if (res.res) {
          this.$message.success('添加成功')
          this.getAllTaskByUserId()
        }
      })
      this.addLearningTask = false
    }
  }
}
</script>

<style lang="less" scoped>
.centerStyle {
  display: flex;
  align-items: center;
}
.content {
  height: 100%;
  padding: 20px;
  .title {
    height: 62px;
    box-shadow: 0px 0px 5px rgba(7, 27, 41, 0.1);
    margin-bottom: 12px;
    /*border: 1px solid #de7f71;*/
    button {
      font-size: 14px;
      line-height: 25px;
      /*color: #fffefe;*/
      height: 40px;
      margin-right: 15px;
    }
  }
  .table {
    flex: auto;
    margin-bottom: 20px;
    /*height: calc(100% - 110px);*/
    .taskTable {
      cursor: pointer;
      .finLearn {
        color: lawngreen;
      }
      .noLearn {
        color: red;
      }
      ::v-deep .ivu-table-body span {
        font-size: 12px;
        /*line-height: 25px;*/
        color: #5e6470;
        opacity: 1;
        white-space: nowrap;
      }
      ::v-deep .ivu-table-header span {
        font-size: 14px;
        /*line-height: 28px;*/
        color: #3a4e64;
        font-weight: 400;
      }
    }
    .iconStyle {
      font-size: 15px;
      .icon {
        cursor: pointer;
        /*color: #bdc3cb;*/
      }
      svg:first-child {
        margin-right: 5px;
      }
    }
  }
  .page {
    height: 45px;
  }
}
::v-deep .ivu-tag-dot {
  border: none !important;
  background: none !important;
  padding: 0;
}
</style>
