<template>
  <Modal class="updateModal" width="517" v-model="value" footer-hide :mask-closable="false" @on-cancel="close">
    <div class="title">{{ title }}</div>
    <div class="content">
      <ul>
        <li>
          <span><b style="color: #ff0000">*</b>课程学习百分比设置(%)</span>
          <el-slider v-model="course" show-input />
          <!--            <slider v-model="course" show-input/>-->
        </li>
        <li>
          <span style="display: flex;">
            <b style="color: #ff0000">*</b>
            测试及格分设置&nbsp;&nbsp;&nbsp;&nbsp;<span style="color: #6A8ABE;">是否使用试卷及格分</span>&nbsp;&nbsp;
            <checkbox v-model="IsSelected" />
          </span>
          <InputNumber style="margin-top: 12px" :max="100" :min="0" v-model="passPoint" />
        </li>
        <li style="display: flex;flex-direction: column">
          <span><b style="color: #ff0000">*</b>测试次数设置(最多5次)</span>
          <InputNumber style="margin-top: 12px" :max="5" :min="0" v-model="testNum" />
        </li>
      </ul>
    </div>
    <div class="footer">
      <Button @click="close">取消</Button>
      <Button type="primary" @click="upTask">确定</Button>
    </div>
  </Modal>
</template>

<script>
export default {
  name: 'UpdateTask',
  props: {
    toSet: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '修改'
    },
    courseId: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      IsSelected: this.toSet,
      passPoint: 0,
      testNum: 0,
      course: 75,
      value: this.toSet
    }
  },
  watch: {
    IsSelected: {
      handler(val) {
        if (val) {
          this.passPoint = 60
        }
      },
      immediate: true
    }
  },
  methods: {
    close() {
      this.$emit('on-cancel', false)
    },
    upTask() {
      const studyTaskDTOS = []
      this.courseId.map(c => {
        studyTaskDTOS.push({
          courseId: c,
          passExamScore: this.passPoint,
          examMaxCount: this.testNum,
          passProgress: this.course
        })
      })
      this.$emit('upTaskByUserId', studyTaskDTOS)
      this.IsSelected = false
    }
  }
}
</script>

<style lang="less" scoped>
.updateModal {
  padding: 30px;
  display: flex;
  flex-direction: column;
  .title {
    text-align: center;
    color: #3a4e64;
    line-height: 42px;
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 30px;
  }
  .content {
    width: 100%;
    height: 319px;
    border-radius: 4px;
    border: 1px solid #dedede;
    padding: 22px;
    ul {
      width: 100%;
      height: 100%;
      list-style: none;
      font-size: 14px;
      color: #333;
      font-weight: 400;
      line-height: 28px;
      li {
        height: 104px;
        border-bottom: 1px solid #eaedf1;
      }
      li:first-child {
        height: 109px;
      }
      li:last-child {
        border-bottom: none;
      }
    }
  }
  .footer {
    margin: 30px 0 40px 0;
    text-align: center;
    height: 40px;
    button {
      width: 90px;
      height: 36px;
    }
    button:first-child {
      margin-right: 20px;
    }
  }
}
</style>
