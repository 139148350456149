import config from '../config/config'
import { axios } from './index'

const baseUrl = config.baseUrl + '/course/api/v1/study/task'

export default {
  getAllTask(searchStudyTaskDTO) {
    return axios.post(`${baseUrl}/get/department`, searchStudyTaskDTO)
  },
  addLearnTaskByDepart(departmentId, departmentAddStudyTaskDTO) {
    return axios.post(`${baseUrl}/batch/allocate/department?departmentId=` + departmentId, departmentAddStudyTaskDTO)
  },
  addLearnTask(departmentAddStudyTaskDTO) {
    return axios.post(`${baseUrl}/batch/allocate`, departmentAddStudyTaskDTO)
  },
  updateTask(operatorId, userId, studyTaskDTOS) {
    return axios.post(`${baseUrl}/batch/modify?userId=${userId}&operatorId=${operatorId}`, studyTaskDTOS)
  },
  batchClearBySelectIds(clearStudyTaskDTO) {
    return axios.post(`${baseUrl}/batch/clear`, clearStudyTaskDTO)
  },
  batchClearDepartById(operatorId, departmentId) {
    return axios.post(`${baseUrl}/batch/clear/department?departmentId=${departmentId}&operatorId=${operatorId}`)
  },
  getAllTaskByUserId(form, userId) {
    // 通过userId获取所有学习任务
    return axios.post(`${baseUrl}/get/user/detail?userId=${userId}`, form)
  },
  batachDelTask(deleteStudyTaskListDTO) {
    return axios.post(`${baseUrl}/batch/delete`, deleteStudyTaskListDTO)
  },
  getTaskExamByCourseIdAndUserId(userId, courseId) {
    // userId courseId 获取课程下的考试信息
    return axios.get(`${baseUrl}/get/course/exam?userId=${userId}&courseId=${courseId}`)
  }
}
