<template>
  <el-dialog class="taskModal yt-dialog yt-dialog-medium" top="20px" :close-on-click-modal="false" :visible="openModal" @close="close">
    <div slot="title" class="title">{{ title }}学习任务</div>
    <div class="content" style="text-align: left">
      <div class="left">
        <Tree :data="departTreeList" @on-check-change="checkCourse" show-checkbox />
      </div>
      <div class="right">
        <ul>
          <li>设置学习完成任务条件</li>
          <li>
            <span><b style="color: #ff0000">*</b>课程学习百分比设置(%)</span>
            <slider v-model="course" show-input />
          </li>
          <li>
            <span style="display: flex;">
              <!--                <b style="color: #ff0000">*</b>-->
              测试及格分设置&nbsp;&nbsp;&nbsp;&nbsp;<span style="color: #6A8ABE;">是否使用试卷及格分</span>&nbsp;&nbsp;
              <checkbox v-model="IsSelected" />
            </span>
            <InputNumber style="margin-top: 12px" :max="100" :min="0" v-model="passPoint" />
          </li>
          <li style="display: flex;flex-direction: column">
            <span>测试次数</span>
            <InputNumber :max="5" :min="0" v-model="testNum" />
          </li>
        </ul>
      </div>
    </div>
    <div class="footer">
      <Button @click="close">取消</Button>
      <Button type="primary" @click="submitAdd">{{ title === '修改' ? title : '确定' }}</Button>
    </div>
  </el-dialog>
</template>

<script>
import functionApi from '../../../../api/functionApi'
import course from '../../../../api/course'
import vuescroll from 'vuescroll'
export default {
  name: 'learnTask',
  components: { vuescroll },
  props: {
    open: {
      type: Boolean,
      default: false
    },
    departmentName: {
      type: String
    },
    IsDepart: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '设置'
    }
  },
  mounted() {
    this.getAllDepart()
    this.userId = this.$store.state.user.info.userId
  },
  data() {
    return {
      IsSelected: false,
      course: 75,
      passPoint: 50,
      testNum: 1,
      courseList: [],
      departTreeList: [],
      IsOpen: this.open,
      departCourse: [],
      departList: [],
      studyTaskDTOS: [],
      userId: 0,
      savePoints: 0,
      openModal: this.open
    }
  },
  watch: {
    IsSelected: function(val) {
      if (val) {
        this.savePoints = this.passPoint
        this.passPoint = 60
      } else {
        this.passPoint = this.savePoints
      }
    }
  },
  methods: {
    close() {
      this.$emit('close', false)
    },
    checkCourse(course) {
      this.studyTaskDTOS = []
      this.courseList = course
    },
    submitAdd() {
      if (this.courseList.length === 0) {
        this.departTreeList.map(item => {
          if (item.checked === true) {
            this.courseList.push(item)
          }
        })
      }
      if (this.courseList.length <= 0) return this.$message({ message: '分类不可为空', type: 'warning' })
      this.courseList.map(c => {
        if (c.courseId) {
          this.studyTaskDTOS.push({
            courseId: c.courseId,
            examMaxCount: this.testNum,
            passExamScore: this.passPoint,
            passProgress: this.course
          })
        }
      })
      const payLoad = {
        IsUpTask: this.title !== '设置',
        operatorId: this.userId,
        studyTaskDTOS: this.studyTaskDTOS
      }
      // if (this.studyTaskDTOS.length === 0)
      //   return this.$message({ message: '请选择岗位下的课程', type: 'warning' })
      this.$emit('addLearnTask', payLoad)
    },
    getAllDepart() {
      const id = 0
      course.getCategoryTree(id).then(res => {
        this.departTreeList.push({
          id: 0,
          title: '所有课程',
          children: []
        })
        this.getAllDepartCourse({ id: 0 })
        res.res.map(item => {
          this.departTreeList.push({
            id: item.id,
            title: item.name,
            children: []
          })
          this.getAllDepartCourse(item)
        })
      })
    },
    getAllDepartCourse(node) {
      course.getCategoryCoursesAll(node.id).then(res => {
        this.departCourse = res.res
        const course = []
        this.departCourse.map(res => {
          course.push({
            courseId: res.courseId,
            title: res.name
          })
        })
        this.departTreeList.map(depart => {
          if (depart.id === node.id) {
            depart.children = course
            if (course.length === 0) {
              depart.disabled = true
            }
          }
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>
.taskModal {
  //padding: 20px;
  ::v-deep .el-dialog {
    margin-bottom: 0;
  }
  ::v-deep .el-dialog__body {
    max-height: 85vh;
  }
  .title {
    font-size: 20px;
    line-height: 42px;
    color: #3a4e64;
    text-align: center;
  }
  .content {
    display: flex;
    width: 100%;
    height: 509px;
    margin-top: 30px;
    .left {
      overflow-y: auto;
      width: 433px;
      height: 100%;
      border: 1px solid #e4e9ef;
      margin-right: 17px;
      border-radius: 4px;
      padding: 16px 12px;
    }
    .right {
      flex: auto;
      height: 100%;
      border: 1px solid #e4e9ef;
      border-radius: 4px;
      /*padding: 22px;*/
      ul {
        list-style: none;
        li {
          font-size: 14px;
          line-height: 28px;
          color: #333;
          height: 109px;
          padding: 12px 22px;
          border-bottom: 1px solid #eaedf1;
        }
        li:first-child {
          height: 60px;
          font-size: 16px;
          line-height: 21px;
          color: #3a4e64;
        }
      }
    }
  }
  .footer {
    margin-top: 24px;
    text-align: center;
    height: 40px;
    button {
      width: 90px;
      height: 36px;
    }
    button:first-child {
      margin-right: 20px;
    }
  }
}
</style>
